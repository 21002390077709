<template>
    <form class="consumer-info" @submit.prevent="submitInfoRequest">

        <div class="row">
            <div class="col-12 consumer-info__form-heading">
                {{$t("message.consumerInfoRequest")}}
            </div>

            <div class="col-12 consumer-info__content-primary" v-html="primaryContent"></div>
        </div>

        <div class="row">
            <!-- INPUT AND BUTTON COLUMN -->
            <div class="col-12 col-md-4 consumer-info__input-block">
                <h2 class="consumer-info__header">
                    {{$t("message.search")}}
                </h2>

                <!-- ORDER NUMBER INPUT -->
                <div class="consumer-info__input">
                    <label class="input__label">
                        {{$t("message.orderNumber")}}
                        <input
                            name="order number"
                            type="text"
                            maxlength="100"
                            v-model="orderNumberComputed"
                            data-vv-name="orderNumber"
                            v-validate="'required|numeric'"
                            :class="['input__field', {'input__field--error': errors.has('orderNumber')}]"/>
                    </label>
                    <small v-if="errors.has('orderNumber')" class="input__error-reason">
                        {{ (errors.first('orderNumber')) }}
                    </small>
                </div>

                <!-- LAST NAME INPUT -->
                <div class="consumer-info__input">
                    <label class="input__label">
                        {{$t("message.lName")}}
                        <input
                            name="last name"
                            type="text"
                            maxlength="100"
                            v-model="lastNameComputed"
                            data-vv-name="lastName"
                            v-validate="{ required: true, regex: /^[a-zA-Z]+[a-zA-Z .,'-]*$/ }"
                            :class="['input__field', {'input__field--error': errors.has('lastName')}]"/>
                    </label>
                    <small v-if="errors.has('lastName')" class="input__error-reason">
                        {{ (errors.first('lastName')) }}
                    </small>
                </div>

                <!-- EMAIL INPUT -->
                <div class="consumer-info__input">
                    <label class="input__label">
                        {{$t("message.email")}}
                        <input
                            name="email"
                            type="text"
                            maxlength="100"
                            v-model="emailComputed"
                            data-vv-name="email"
                            v-validate="'required|email'"
                            :class="['input__field', {'input__field--error': errors.has('email')}]"/>
                    </label>
                    <small v-if="errors.has('email')" class="input__error-reason">
                        {{ (errors.first('email')) }}
                    </small>
                </div>

                <!-- SEARCH AND CLEAR BUTTONS -->
                <div class="row">
                    <div class="col-12 consumer-info__button-row">
                        <button class="col-5 button consumer-info__button-primary" type="submit">
                            {{$t("message.search")}}
                        </button>

                        <button class="col-5 button consumer-info__button-secondary" @click.prevent="clearForm">
                            {{$t("message.clear")}}
                        </button>
                    </div>
                </div>
            </div>
            <!-- END INPUT AND BUTTON COLUMN -->

            <!-- RESULTS COLUMN -->
            <template v-if="showResults">
                <div v-if="resultsHaveBeenFound" class="col-12 col-md-8 consumer-info__results">
                    <h2 class="consumer-info__header">
                        {{$t("message.resultsForOrderNumber")}}{{ orderNumberToDisplay }}
                    </h2>

                    <div class="consumer-info__why-title">
                        {{$t("message.consumerInfoWhyTitle")}}
                    </div>

                    <div class="consumer-info__why">
                        {{$t("message.consumerInfoWhy")}}
                    </div>

                    <div v-for="(info, title) in consumerInfoForDisplay"
                         class="consumer-info__info-container" :key="title">
                        <span class="consumer-info__info-title">{{ $t(title) }}: </span>
                        <span>{{ info }}</span>
                    </div>
                </div>

                <div v-else class="col-12 col-md-8 consumer-info__results">
                    {{$t("message.noResultsFound")}}
                </div>
            </template>

            <div class="col-12 consumer-info__content-footer" v-html="footerContent"></div>
        </div>
    </form>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { extend } from 'underscore';

export default {
	title: 'ConsumerInfo',
	computed: {
		...mapState('consumerInfo', [
			'consumerInfo',
			'emailUserInput',
			'lastNameUserInput',
			'orderNumber',
			'orderNumberToDisplay',
			'resultsHaveBeenFound',
			'showResults'
		]),
		...mapState('content/consumerInfo', [
			'footerContent',
			'primaryContent'
		]),
		...mapState('search', ['dealership_id']),
		emailComputed: {
			get() {
				return this.emailUserInput;
			},
			set(email) {
				this.setEmailUserInput(email);
			}
		},
		lastNameComputed: {
			get() {
				return this.lastNameUserInput;
			},
			set(lastName) {
				this.setLastNameUserInput(lastName);
			}
		},
		orderNumberComputed: {
			get() {
				return this.orderNumber;
			},
			set(orderNumber) {
				this.setOrderNumber(orderNumber);
			}
		},
		consumerInfoForDisplay() {
			const properTitles = {
				firstName: 'message.fName',
				lastName: 'message.lName',
				email: 'message.emailSingle'
			};
			const consumer = this.consumerInfo.consumer;
			const infoToDisplay = {};

			for (const key in properTitles) {
				const title = properTitles[key];
				const value = consumer[key];

				extend(infoToDisplay, {
					[title]: value
				});
			}

			const { line1, city, state, zipCode } = consumer.address;
			const address = `${line1}, ${city}, ${state}, ${zipCode}`;
			const addressTitle = 'message.address';

			extend(infoToDisplay, {
				[addressTitle]: address
			});

			return infoToDisplay;
		}
	},
	methods: {
		...mapActions('consumerInfo', [
			'clearFormData',
			'getConsumerInfo'
		]),
		...mapActions('content/consumerInfo', [
			'fetchFooterContent',
			'fetchPrimaryContent'
		]),
		...mapMutations('consumerInfo', [
			'setEmailUserInput',
			'setLastNameUserInput',
			'setOrderNumber'
		]),
		clearForm() {
			this.clearFormData().then(() => {
				this.errors.clear();
			});
		},
		submitInfoRequest() {
			const query = {
				orderNumber: this.orderNumber,
				lastNameUserInput: this.lastNameUserInput,
				emailUserInput: this.emailUserInput
			};

			this.$validator.validateAll()
				.then((isInfoValid) => {
					if (isInfoValid) {
						this.getConsumerInfo(query);
					}
				});
		}
	},
	created() {
		this.fetchFooterContent();
		this.fetchPrimaryContent();
		this.$rudderstack.page(this.$route.meta.pageCategory, this.$route.meta.pageCategory, {
			...this.$route.query,
			'dealership_id': this.dealership_id
		});
	}
};
</script>
