import { mapGetters, mapState } from 'vuex';
import {
	calculateServicesCost,
	generateGlobalProductProperties,
	generateGlobalProductPricing
} from '../helpers';

export const RudderstackCheckoutMixin = {
	methods: {

		/**
		 * Fires when the Checkout page is done loading
		 *
		 * @param {object} properties - Properties
		 * @arg {number} properties.subTotal - Subotal after applying discount(s) & coupon(s)
		 * @arg {number} properties.total - Total of transaction, excluding the shipping and tax details
		 * @arg {number} properties.tax - Total tax associated with the order or transaction
		 * @arg {array} properties.promotions - Total discount associated with the transaction
		 * @arg {array} properties.rebates - Rebates associated with the transaction
		 * @arg {string} properties.currency - Currency code associated with an order or transaction
		 * @arg {array} properties.sku - Contains the list of products in the order or transaction
		 * @arg {array} properties.products - Tire objects containing: install price, tire price, quantity of each tire bought
		 * @arg {array} properties.product_id - Array of product id's
		 * @arg {array} properties.quantity - Total number of all tires purchased
		 * @arg {string} properties.service_added - 'Y' or 'N' if services have been selected
		 * @arg {array} properties.services - Array of Services Requested
		 * @arg {number} properties.services_cost - Total Cost of Services Selected
		 * @arg {string} properties.appointment_type - Eg. Drop Off
		 * @arg {Date} properties.date_selected - Date of Appointment Selected
		 * @arg {Date} properties.time_selected - Time selected for the appointment
		 * @arg {string} properties.financing_avalaible - 'Y' or 'N'
		 * @arg {object} properties.appointment - all the appointment info (date, time, type)
		 * @returns {void}
		 */
		trackCheckoutStarted() {
			this.$rudderstack.track('CHECKOUT_STARTED', {
				'total': Number(this.orderPayment.grandTotalAmount),
				'subTotal': Number(this.orderPayment.subTotalAmount),
				'tax': Number(this.orderPayment.supplementalChargesAmount),
				'promotions': this.orderDetails.promotions,
				'currency': 'USD',
				'products': generateGlobalProductProperties(this.tiresInCart.products),
				'product_pricing': generateGlobalProductPricing(this.tiresInCart.products),
				'service_added': this.appointment.services.length > 0 ? 'Y' : 'N',
				'services': this.appointment.services,
				'services_cost': calculateServicesCost(this.appointment.services),
				'appointment_type': this.appointment.type.name,
				'appointment_date': this.appointment.time.appointmentDate,
				'appointment_time': this.appointment.time.appointmentTime,
				'financing_avalaible': this.isFinancingAvailable
			});
		},

		/**
		 * When a visitor successfully completes an input (on blur) on the checkout form.
		 *
		 * @param {object} properties - Properties of the event
		 * @arg {string} properties.checkout_step - The label of the input field that was completed
		 * @returns {void}
		 */
		trackCheckoutStepModified(properties) {
			const { checkout_step } = properties;

			if (!checkout_step || this.fields[checkout_step].invalid) return;

			this.$rudderstack.track('CHECKOUT_STEP_MODIFIED', { checkout_step });

			this.trackCheckoutFormCompleted();
		},

		async trackCheckoutFormCompleted() {
			// Unfortunately this is the best way to check to see
			// if all form fields are valid in order to fire the trackCheckoutFormCompleted()
			const allFieldsValid = await this.$validator.validate();

			if (allFieldsValid) {
				this.$rudderstack.track('CHECKOUT_FORM_COMPLETED', {
					'checkout_completed': 'Y'
				});
			}
		},

		/**
		 * When a visitor successfully enters payment information during the checkout process.
		 * Fires when ALL payment fields are completed.
		 *
		 * @return {void}
		 */
		trackPaymentInfoEntered() {
			if (!this.hasCreditCardCheckout || !this.isCreditCardValid) return;

			this.$rudderstack.track('PAYMENT_INFO_ENTERED', { 'payment_method': 'Credit Card Lite' });
		}
	},

	computed: {
		...mapState('cart', ['tiresInCart']),
		...mapGetters('cart/order', ['orderPayment', 'orderDetails']),
		...mapState('appointment', ['appointment']),
		isFinancingAvailable() {
			return Object.prototype.hasOwnProperty.call(this.tiresInCart, 'financing');
		},
		isCreditCardValid() {
			return !this.ccErroring.number && !this.ccErroring.expired && !this.ccErroring.cvn;
		}
	}
};
