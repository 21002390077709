<template>
    <div class="col-12">
        <div v-html="formLocationConfirmation"></div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	props: {
		query: {
			type: Object,
			required: true
		}
	},
	computed: {
		...mapState('content/noDealer', [
			'formLocationConfirmation'
		]),
		...mapState('search', ['dealership_id'])
	},
	created() {
		this.$rudderstack.page(this.$route.meta.pageCategory, this.$route.meta.pageCategory, {
			...this.$route.query,
			'dealership_id': this.dealership_id
		});
	}
};
</script>
