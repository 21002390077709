<template>
    <div class="error500">
        <section class="error500-hero">
            <h1 class="error500-hero__title">{{ $t('message.oops') }}...</h1>
            <p class="error500-hero__body">{{ $t('message.issueFindingTires') }}</p>
            <IconNoTire class="error500-hero__icon" />
            <a href="/" class="error500-hero__link">{{ $t('message.returnToHomepage') }}</a>
        </section>

        <section v-if="submitted" class="error500-success" role="alert">
            <h2>Thank you for contacting us.</h2>
            <a href="/" class="button button--primary">{{ $t('message.returnToHomepage') }}</a>
        </section>

        <Error500Form v-else @submitted="handleSubmitted" :query="query" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import IconNoTire from '@/components/IconNoTire.vue';
import Error500Form from '@/components/Error500Form.vue';

export default {
	name: 'Error500',

	props: {
		query: {
			required: true,
			type: Object
		}
	},

	components: {
		IconNoTire,
		Error500Form
	},

	data() {
		return {
			submitted: false
		};
	},

	methods: {
		handleSubmitted() {
			this.submitted = true;
		}
	},

	computed: {
		...mapState('search', ['dealership_id'])
	},

	created() {
		this.$rudderstack.page(this.$route.meta.pageCategory, this.$route.meta.pageCategory, {
			...this.$route.query,
			'dealership_id': this.dealership_id
		});
	}
};
</script>
