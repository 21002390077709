import { mapState, mapGetters } from 'vuex';
import { generateGlobalProductProperties, generateGlobalProductPricing } from '../helpers';

export const RudderstackProductsMixin = {
	methods: {

		/**
		 * When a visitor views details about a specific product
		 *
		 * @param {object} properties - details of the event
		 * @param {array} properties.productSet - array of tire objects
		 * @param {number} [properties.position=null] - where the tire was in the list of products
		 * @param {object} properties.quantity - Number of tires ordered
		 * @param {number} properties.rating - array of tire objects
		 * @param {number} properties.reviews - where the tire was in the list of products
		 * @returns {void}
		 */
		trackProductViewed(properties = {}) {
			const { position = null } = properties;

			this.$rudderstack.track('PRODUCT_DETAILS_VIEWED', {
				'products': generateGlobalProductProperties(this.singleProduct.products),
				'product_pricing': generateGlobalProductPricing(this.singleProduct.products),
				'promotions': this.promos,
				'product_position': position,
				'quantity': this.query.qty,
				'rating': this.averageRating,
				'reviews': this.reviewCount
			});
		},

		/**
		 * Whenever a customer clicks on "Choose".
		 *
		 * @param {object} properties - details of the event
		 * @param {array} properties.productSet - array of tire objects
		 * @param {number} properties.position - where the tire was in the list of products
		 * @param {object} properties.quantity - Number of tires ordered
		 * @param {string} [properties.sort=null] - The sort order if applicable
		 * @param {string} [properties.filteredBy=null] - How the tires are being filtered
		 * @returns {void}
		*/
		trackProductAdded(properties = {}) {
			const {
				sort = null,
				filteredBy = null,
				position = null
			} = properties;

			const pageSourceMap = {
				'/app/product': 'Product Results Page',
				'/app/details': 'Product Details Page',
				'/app/compare': 'Compare Page'
			};

			this.$rudderstack.track('PRODUCT_ADDED', {
				'products': generateGlobalProductProperties(this.productSet.products),
				'product_pricing': generateGlobalProductPricing(this.productSet.products),
				'promotions': this.productSet.promotions,
				'product_position': position,
				'quantity': this.chosenQty.toString(),
				'category': filteredBy,
				'sort_order': sort,
				'page_source': pageSourceMap[this.$route.path]
			});
		},

		/**
		 * When the user clicks the "Compare" button to generate a product comparison.
		 * When the user adds a product to compare from the <select> dropdown (on compare page)
		 * When the "Differences" & "Hide Similar" checkboxes on the compare page are toggled.
		 *
		 * @param {object} properties - the properties to send along in the event
		 * @arg {array} properties.productSets - An array of products to compare
		 * @arg {boolean} [properties.difference_checkbox=null] - "Differences" checkbox is toggled
		 * @arg {boolean} [properties.hide_similar_checkbox=null] - "Hide Similar" checkbox is toggled
		 * @returns {void}
		 */
		trackProductCompared() {
			const products = this.productSetsToCompare.reduce((acc, currProd) => {
				acc.push(currProd.products);

				return acc;
			}, []);

			this.$rudderstack.track('PRODUCT_COMPARED', {
				'products': products.map((p) => generateGlobalProductProperties(p)),
				'product_pricing': products.map((p) => generateGlobalProductPricing(p)),
				'difference_checkbox': this.showDiff ? 'Y' : 'N',
				'hide_similar_checkbox': this.hideSame ? 'Y' : 'N'
			});
		}
	},

	computed: {
		...mapState('products', [
			'productSetsToCompare',
			'singleProduct',
			'qtySelected',
			'defaultQty',
			'showDiff',
			'hideSame'
		]),
		...mapGetters('products', ['productSetsToCompare']),
		...mapState('productResults', ['productReviews']),
		...mapGetters('productResults', ['sortBy']),
		productReview() {
			const filteredProduct = this.productReviews[this.modelID];

			if (typeof filteredProduct !== 'undefined'
				&& filteredProduct.paging.total_results > 0
				&& filteredProduct.results[0].rollup.average_rating !== null
			) {

				return filteredProduct;

			}

			return false;
		},
		reviewCount() {
			return this.productReview ? this.productReview.paging.total_results : 0;
		},
		averageRating() {
			return this.productReview ? this.productReview.results[0].rollup.average_rating : 0;
		},
		promos() {
			return (this.singleProduct.length > 0 && this.singleProduct.promotions.length > 0)
				? this.singleProduct.promotions
				: null;
		},
		chosenQty() {
			return this.qtySelected.length > 0 ? this.qtySelected : this.defaultQty;
		}
	}
};
